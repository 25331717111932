/* App.css */

body {
  margin: 50px;
  font-family: Arial, sans-serif;
  background-color: #E0E0E2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-info {
  border: 1px solid #FA9F42;
  min-width: 90%;
}

.card-actions {
  border: 1px solid #721817;
  min-width: 90%;
}

h1 {
  color: #721817;
  margin-bottom: 20px;
}

p {
  color: #2B4162;
  margin: 10px 0;
}

strong {
  color: #0B6E4F;
  font-weight: bold;
}

input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: calc(100% - 22px);
  margin-bottom: 20px; /* Increased margin to push buttons further down */
  box-sizing: border-box;
}

button {
  background-color: #721817;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  margin: 5px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #5c0f0f;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
  color: #666;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.button-container button:nth-of-type(2) {
  background-color: #FA9F42;
}

.button-container button:nth-of-type(2):hover {
  background-color: #e0872f;
}

div {
  margin-bottom: 20px;
}

.alert {
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert.success {
  background-color: #0B6E4F;
}

.alert.error {
  background-color: #721817;
}

.alert.info {
  background-color: #FA9F42;
}

.alert .close-btn {
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  margin-left: 15px;
}

.alert .close-btn:hover {
  color: #ddd;
}

.logo {
  width: 150px;
  margin: 0 auto 20px auto;
}

.footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffffff; /* You can change the color to match your theme */
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.dextools-link {
  color: #721817; /* Primary color */
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  background-color: #FA9F42; /* Secondary color */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.dextools-link:hover {
  background-color: #2B4162; /* Accent color for hover */
  color: #FFFFFF; /* White text on hover */
}

/* Responsive Styles */
@media (max-width: 600px) {
  .container {
    width: 95%;
    padding: 15px;
  }

  .button-container button {
    width: 100%;
    max-width: none;
  }
  .logo {
    width: 110px;
    margin: 20px auto 0px auto;
  }
}
